<template>
<div>
  <v-row v-if="requiredLabels" no-gutters>
    <v-col cols="12" v-for="number in requiredLabels" :key="number" >
      <p>{{labels[number]}}</p>
    </v-col>
    <v-col cols="12" v-if="requiredLabels.length == 0">
      <p>Es gibt keine kennzeichnungspflichtigen Allergene und Zusatzstoffe.</p>
    </v-col>
  </v-row>
  <v-row v-else>
    <v-col cols="12">
      <p> Keine Informationen zu den Allergenen und Zusatzstoffen. Wenden Sie sich bitte telefonisch an uns!</p>
    </v-col>
  </v-row>
</div>
</template>

<script>
export default {
name: "AllergensViewer",
  data: () => {
    return{
      labels : {
        1: "Glutenhaltiges Getreide sowie daraus hergestellte Erzeugnisse",
        2: "Krebstiere und daraus gewonnene Erzeugnisse",
        3: "Eier und daraus gewonnene Erzeugnisse",
        4: "Fisch und daraus gewonnene Erzeugnisse",
        5: "Erdnüsse und daraus gewonnene Erzeugnisse",
        6: "Soja und daraus gewonnene Erzeugnisse",
        7: "Milch und daraus gewonnene Erzeugnisse (einschließlich Laktose)",
        8: "Schalenfrüchte, sowie daraus hergestellte Erzeugnisse",
        9: "Sellerie und daraus gewonnene Erzeugnisse",
        10: "Senf und daraus gewonnene Erzeugnisse",
        11: "Sesamsamen und daraus gewonnene Erzeugnisse",
        12: "Schwefeldioxid und Sulfite in einer Konzentration von mehr als 10 mg/kg oder 10 mg/l",
        13: "Lupinen und daraus gewonnene Erzeugnisse",
        14: "Weichtiere, und daraus gewonnene Erzeugnisse",
        15: "mit Farbstoff",
        16: "mit Konservierungsstoff/en",
        17: "mit Nitritpökelsalz",
        18: "mit Nitrat",
        19: "mit Antioxidationsmittel",
        20: "mit Geschmacksverstärker",
        21: "geschwefelt",
        22: "geschwärzt",
        23: "gewachst",
        24: "mit Phosphat",
        25: "mit Süßungsmitteln",
        26: "enthält eine Phenylalaninquelle",
        27: "koffeinhaltig",
        28: "chininhaltig"
      }
    }
  },
  props:{
    requiredLabels: Array
  },
}
</script>

<style scoped>

</style>