<template>
    <div>
        <fixed-sidebar-layout :loading="!menuLoaded">
            <div slot="content">
              <div class="categoryBackground">
              <InteractiveMenu
                                 :foodItems="foodItems"
                                 :foodCategories="foodCategories"
                                 :disabled="!acceptingOrders || !isCurrentTimeInOpeningHours">
                </InteractiveMenu>
              </div>
            </div>
            <shopping-cart zip-selectable slot="sidebar"></shopping-cart>
        </fixed-sidebar-layout>

        <ZipSelector
                :zipCodes="zipCodes"
                v-if="hasCheckedLogin && hasCheckedInfo">
        </ZipSelector>
        <v-dialog
                v-if="$vuetify.breakpoint.smAndDown && acceptingOrders"
                fullscreen
                v-model="cartfloat"
        >
            <template v-slot:activator="{ on }">
                <v-slide-y-reverse-transition>
                    <v-btn v-if="numberOfItemsInCart > 0" class="floatingButton" style="z-index: 10;" fixed bottom
                           right x-large
                           v-on="on">
                        <v-badge overlap bottom left :content="numberOfItemsInCart">
                            <v-icon>{{ mdiShopping }}</v-icon>
                        </v-badge>
                        {{(totalPrice/100)|currency}}
                    </v-btn>
                </v-slide-y-reverse-transition>
            </template>
            <v-card>
                <v-card-title class="pb-0">
                  Warenkorb
                    <v-spacer></v-spacer>
                    <v-btn icon @click="cartfloat = false">
                        <v-icon>{{ mdiClose }}</v-icon>
                    </v-btn>
                </v-card-title>
                <v-container>
                    <shopping-cart class="transition-fast-in-fast-out"></shopping-cart>
                </v-container>
            </v-card>
        </v-dialog>

    </div>
</template>
<script>
    import InteractiveMenu from "@/components/InteractiveMenu";
    import FixedSidebarLayout from "@/components/FixedSidebarLayout";
    import ShoppingCart from "@/components/ShoppingCart";
    import ZipSelector from "@/components/ZipSelector";
    import {mapGetters} from "vuex";
    import {store} from "@/store";
    import { mdiShopping, mdiClose} from '@mdi/js'


    export default {
        name: 'b-menu',

        components: {
            ShoppingCart,
            InteractiveMenu,
            ZipSelector,
            FixedSidebarLayout
        },
        computed: {
            ...mapGetters({
                totalPrice: 'shoppingCartStore/totalPrice',
                foodCategories:'menuStore/foodCategories',
                foodItems:'menuStore/foodItems',
                hasCheckedCart:'shoppingCartStore/hasCheckedCart',
                numberOfItemsInCart: 'shoppingCartStore/numberOfItemsInCart',
                zipCodes: 'restaurantStore/sortedZipCodes',
                menuLoaded:'menuStore/menuLoaded',
                hasCheckedLogin:'userStore/hasCheckedLogin',
                hasCheckedInfo:'restaurantStore/hasCheckedInfo',
                acceptingOrders:'restaurantStore/acceptingOrders',
                isCurrentTimeInOpeningHours: 'restaurantStore/isCurrentTimeInOpeningHours'


            }),
        },
        data: () => ({
            mdiShopping:mdiShopping,
            mdiClose:mdiClose,
            cartfloat: false,
        }),
        created() {
            if (this.hasCheckedInfo) {
                if(!this.menuLoaded){
                    store.dispatch('menuStore/fetchFoodCategories');
                }
                if(!this.hasCheckedCart){
                    store.dispatch('shoppingCartStore/getCartFromLocalStorage');
                }
            }
        },
        watch: {
            hasCheckedInfo() {
                if (!this.menuLoaded) {
                    store.dispatch('menuStore/fetchFoodCategories');
                }
                if(!this.hasCheckedCart){
                    store.dispatch('shoppingCartStore/getCartFromLocalStorage');
                }
            }
        }
    };
</script>
<style scoped lang="sass">
.categoryBackground
  background-image: url(../assets/backgrounds/background.jpg)
  background-repeat: repeat
  background-attachment: fixed

</style>
