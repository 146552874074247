<template>
    <v-card
            :hover="!disabled"
            :disabled="disabled"
            min-height="94"
            height="100%"
            flat
            outlined
            :class="{'mx-2': $vuetify.breakpoint.smAndDown}"
            class="food-item rounded-0"
            @click="sendUpClick"
            :ripple="false"
    >
        <v-layout class="d-flex justify-space-between align-start">
          <div class="body-1 font-weight-medium pl-4 pt-4 pb-3 ">{{item.name}} <v-icon v-if="item.veg"  small style="padding-bottom: 3px"  color="green">{{mdiLeaf}}</v-icon> <v-icon small style="padding-bottom: 3px" @click.stop="sendUpProductInfoClick" color="blue lighten-2">{{ mdiInformation }}</v-icon></div>
            <span class="pr-4 pl-4 pt-4 pb-3 text-no-wrap body-1 currencyText font-weight-bold ">
                        {{item.price/100 | currency}}
            </span>
        </v-layout>
        <v-card-text class="pt-0 pb-5" style="white-space: pre-wrap;">{{item.description}} <br></v-card-text>
    </v-card>
</template>

<script>
import { mdiInformation, mdiLeaf} from '@mdi/js'

    export default {
        name: "FoodSelector",
        components:{},
        data(){
          return{
            mdiInformation,
            mdiLeaf
          }
        },
        props: {
            item: Object,
            disabled: Boolean
        },
        methods: {
            sendUpClick(){
                this.$emit('click', this.item)
            },
            sendUpProductInfoClick(){
              this.$emit('productInfoClick', this.item)
            }

        }
    }
</script>

<style scoped lang="sass">
    .food-item
        background-color: var(--v-cards-base)
        &.selected
          background-color: rgba(235, 243, 245)
        &:hover
            cursor: pointer
            background-color: rgba(235, 243, 245)
    .currencyText
        color: var(--v-primary-base)
</style>
