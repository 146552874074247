<template>
    <v-container :class="{'px-0' : $vuetify.breakpoint.smAndDown}">
    <div v-if="$vuetify.breakpoint.smAndUp && disabled" style="background-color:white" class="display-1 pa-8 mb-6 text-center">Das Restaurant nimmt momentan keine Bestellungen entgegen! <v-icon x-large color="red">{{mdiAlertOutline}}</v-icon></div>
    <div v-else-if="disabled" style="background-color:white" class="headline pa-6 mb-6 text-center">Das Restaurant nimmt momentan keine Bestellungen entgegen! <v-icon color="red">{{mdiAlertOutline}}</v-icon></div>
      <div v-for="(category, index) in foodCategories"
         v-bind:key="category.id"
         v-intersect="{handler: handleScroll, threshold: 1}"
         :id="category.name">
        <div @click="handleCategoryClick(category)"
             style="touch-action: manipulation;" :style="{cursor: $vuetify.breakpoint.mdAndUp ? 'default' : 'pointer'}" >
          <div class="pa-0" style=" background-color: rgba(255,255,255,0.45)" :style="{'border-bottom': '7px solid ' + menuAccentColor}">
            <v-row no-gutters class="pa-0 ma-0" align="center">
              <v-col cols="12">
                <v-img
                    v-if="category.image"
                    :src="getImgUrl(category.image, false)"
                    :lazy-src="getImgUrl(category.image, true)"
                    :height="$vuetify.breakpoint.mdAndUp ?'260px' : '130px'"
                    width="100%"
                    min-width="1px"
                    class="pa-0"
                    :ref="'categoryPic-'+index"
                >
                </v-img>
              </v-col>
            </v-row>
            <v-row class="px-4" no-gutters>
              <v-col cols="11" class="pb-0 pt-3 mb-0 categoryTitle" :id="'category' + category.id">
                <div :class="{'headline' : $vuetify.breakpoint.mdAndUp, 'text-h5' :$vuetify.breakpoint.smAndDown}"
                     class="font-weight-bold">{{ category.name }}
                </div>
              </v-col>
              <v-col cols="1" class="pt-3 pb-0 mb-0 d-flex align-center justify-end" v-if="$vuetify.breakpoint.smAndDown">
                <v-icon x-large>{{ openCategories[category.id] ? mdiChevronUp : mdiChevronDown }}</v-icon>

              </v-col>
              <v-col cols="12" class="pt-1 mt-0 pb-3 mb-0">
                <div style="white-space: pre-wrap;"  :class="{'text-subtitle-1' : $vuetify.breakpoint.mdAndUp}" class="lefont-weight-bold">{{ category.description }}
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      <div
          v-if="openCategories[category.id] || $vuetify.breakpoint.mdAndUp"
          :class="{'px-2' : $vuetify.breakpoint.smOnly, 'px-4':$vuetify.breakpoint.mdAndUp,}">
          <v-row no-gutters :class="{'py-2' : $vuetify.breakpoint.smAndDown, 'py-4':$vuetify.breakpoint.mdAndUp,}">
            <v-col class="py-1 pa-2" cols="12" md="6" lg="6" xl="4"
                   v-for="item in foodItems.filter(x=>x.category == category.id)" v-bind:key="item.id">
              <v-lazy height="100%">
                <FoodSelector
                    :item="item"
                    :disabled="disabled"
                    v-on:click="handleFoodItemClick"
                    v-on:productInfoClick="handleProductInfoClick"
                ></FoodSelector>
              </v-lazy>
            </v-col>
          </v-row>
      </div>
    </div>
    <v-dialog
        v-model="dialog"
        max-width="560px"
        :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card class="pa-2">
        <span v-if="dialogType == 'food'">
          <v-card-title style="word-break: normal" class="justify-space-between">{{ dialogItem.name }}          <v-btn icon @click="dialog = false">
            <v-icon>{{ mdiClose }}</v-icon>
          </v-btn></v-card-title>
          <v-card-subtitle v-if="dialogItem.description" style="white-space: pre-wrap;">{{ dialogItem.description }}
          </v-card-subtitle>
          <extra-selector
              :loading="dialogLoading"
              :item="dialogItem"
              v-on:sendUpExtras="addToCart"
          ></extra-selector>
          </span>
        <div v-if="dialogType =='allergens'">
          <v-card-title>
            <h3>Allergene und Zusatzstoffe</h3>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialog = false">
              <v-icon>{{ mdiClose }}</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-lazy>
              <allergens-viewer :requiredLabels="dialogItem.requiredLabels"></allergens-viewer>
            </v-lazy>
          </v-card-text>
        </div>
      </v-card>
    </v-dialog>
    </v-container>
</template>

<script>
import {menuAccentColor} from "@/restaurantConfig";
import FoodSelector from "@/components/FoodSelector";
import ExtraSelector from "./ExtraSelector";
import {mapGetters, mapActions} from "vuex";
import {store} from "../store";
import Vue from 'vue'
import AllergensViewer from "./AllergensViewer";
import { mdiClose, mdiChevronDown, mdiChevronUp, mdiAlertOutline} from '@mdi/js'


export default {
  name: "InteractiveMenu",
  components: { AllergensViewer, FoodSelector, ExtraSelector},
  props: {
    foodItems: Array,
    foodCategories: Array,
    shoppingCart: Array,
    disabled: Boolean,
  },
  data() {
    return {
      menuAccentColor,
      mdiClose,
      mdiChevronDown,
      mdiChevronUp,
      mdiAlertOutline,
      visible: {},
      dialog: false,
      dialogLoading: false,
      dialogItem: {},
      dialogType: undefined,
      openCategories: {},
    }
  },
  methods: {
    handleScroll(...a) {
      this.$set(this.visible, a[0][0].target.id, a[2])
    },
    handleCategoryClick(category) {
      if(this.openCategories[category.id]) {
        this.openCategories[category.id] = !this.openCategories[category.id]
      } else {
        Vue.set(this.openCategories, category.id, true)
      }
    },
    addToCart(item) {
      store.dispatch("shoppingCartStore/incrementOrAddItem", item);
      this.dialogItem = {}
      this.dialog = false
    },
    handleFoodItemClick(item) {
      this.dialogItem = item
      this.dialogType = 'food'
      this.dialog = true
      this.dialogLoading = true
      this.ensureQueries(item).then(() => {
        this.dialogLoading = false
      })
    },
    handleProductInfoClick(item) {
      this.dialogItem = item
      this.dialog = true
      this.dialogType = 'allergens'
    },
    ...mapActions({
      setCategoryInFocus: 'menuStore/setCategoryInFocus',
      ensureQueries: 'menuStore/ensureQueries'
    }),
    getImgUrl(name, lazy) {
      if(lazy) {
        return require('../assets/categoryPics/lq/' + name)

      } else {
        return require('../assets/categoryPics/' + name)
      }
    },
  },
  computed: {
    firstVisible() {
      return this.foodCategories.find(cat => this.visible[cat.name])
    },
    ...mapGetters({
      categoryToFocus: "menuStore/categoryToFocus"
    })
  },
  watch: {
    firstVisible(a) {
      if(a){
        this.setCategoryInFocus(a.name)
      }
    },
    categoryToFocus(category) {
      /*
      console.log(category)
      this.$refs['lazyCategory' + category][0].isActive = true*/
      Vue.set(this.openCategories, category, true)
      Vue.nextTick().then(() => {
        document.querySelector('#category' + category).scrollIntoView()
      })

    }
  }
}
</script>

<style scoped lang="sass">
.categoryTitle
  scroll-margin-top: 7rem

.categoryBackground
  background-image: url(../assets/backgrounds/background.jpg)
  background-repeat: repeat
  background-attachment: fixed


</style>
