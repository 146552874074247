<template>
    <div>
        <v-dialog v-model="dialog" persistent max-width="600" scrollable>
            <v-card height="580">
                <v-card-title class="headline">Postleitzahl</v-card-title>
                <v-card-text>
                  <v-form v-on:submit.prevent="handleZipSearchGo">
                  <v-text-field class="pt-1" v-model="zipSearch" hide-details outlined type="number" label="Was ist ihre Postleitzahl?"></v-text-field>
                  </v-form>
                  <v-row>
                    <v-col cols="6" md="4" lg="3" class="text-center"  v-for="code in zipCodes.filter(x => x.zipCode.includes(zipSearch))" v-bind:key="code.zipCode">
                      <v-btn block color="primary" x-large text @click="setZipCode(code)">{{code.zipCode}}</v-btn>
                    </v-col>
                    <v-col cols="12" v-if="zipCodes.filter(x => x.zipCode.includes(zipSearch)).length == 0">
                      Nichts gefunden!
                    </v-col>
                  </v-row>

                </v-card-text>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from "vuex";
    export default {
        name: "ZipSelector",
      data() {
        return {
          zipSearch: ""
        }
      },
        props:{
            zipCodes: Array,
        },
        methods: {
            ...mapActions({
                setZipCode: 'userStore/setZipCode',
            }),
          handleZipSearchGo() {
              if(this.zipCodes.filter(x => x.zipCode.includes(this.zipSearch)).length == 1) {
                this.setZipCode(this.zipCodes.filter(x => x.zipCode.includes(this.zipSearch))[0])
                document.activeElement.blur()
              }
          }
        },
        computed: {
            ...mapGetters({
                zipCode: 'userStore/zipCode',
        }),
            dialog(){
                if (this.zipCode==undefined || !this.zipCodes.find(x=> x.zipCode == this.zipCode.zipCode && x.deliveryFee == this.zipCode.deliveryFee)){
                    return true
                } else {
                    return false
                }
            }

        },
    }
</script>

<style scoped lang="sass">

</style>
